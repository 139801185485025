export const useApi = (request, opts) => {
	const config = useConfig()
	opts.headers = Object.assign(opts.headers ?? {}, {
		"Content-Type": "application/json",
		"x-jwt-token": localStorage.getItem('jwt'),
	})
	return $fetch(request, { 
		baseURL: config.baseUrl, 
		...opts 
	})
}