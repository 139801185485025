export const useStore = () => {
	let store = useState('store', () => {
		return {
			user: {
				_id: ''
			}
		}
	})
	return store.value
}
